import Header from "./Header";
import Badge from "./Badge";
import Profile from "./Profile";
import Product from "./Product";
import Contact from "./Contact";
import Footer from "./Footer";
import Thank from "./Thank";


function App() {
  return (
    <>
      <body class="home-blue ">
        <Header />
        <Badge />
        <Profile />
        <Product />
        <Contact/>
        <Thank />
        <Footer/>
      </body>
    </>
  )
}

export default App;
