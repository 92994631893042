import React from 'react'

function Badge() {
    return (
        <>
            <section data-bs-spy="scroll" class="rts-trusted-client2 rts-section-gap">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="text-center">
                                <b class="title" style={{ fontSize: "4rem" }}>Certified</b>
                            </div>
                        </div>
                    </div>
                    <div class="row g-5 mt--0">
                        <div class="swiper mySwiperh1_team">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                    <div class="team-single-one-start">
                                        <div class="card-certified">
                                            <a >
                                                <img src="assets/images/reward/14.jpg" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="team-single-one-start">
                                        <div class="card-certified">
                                            <a >
                                                <img src="assets/images/reward/15.jpg" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="team-single-one-start">
                                        <div class="card-certified">
                                            <a >
                                                <img src="assets/images/reward/13.jpg" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="team-single-one-start">
                                        <div class="card-certified">
                                            <a >
                                                <img src="assets/images/reward/11.jpg" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="team-single-one-start">
                                        <div class="card-certified">
                                            <a >
                                                <img src="assets/images/reward/12.jpg" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="team-single-one-start">
                                        <div class="card-certified">
                                            <a >
                                                <img src="assets/images/reward/03.jpg" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="team-single-one-start">
                                        <div class="card-certified">
                                            <a >
                                                <img src="assets/images/reward/05.png" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="team-single-one-start">
                                        <div class="card-certified">
                                            <a >
                                                <img src="assets/images/reward/04.jpg" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="team-single-one-start">
                                        <div class="card-certified">
                                            <a >
                                                <img src="assets/images/reward/06.jpg" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row g-5 mt--0">
                        <div class="swiper mySwiperh1_teamReverse">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                    <div class="team-single-one-start">
                                        <div class="card-certified">
                                            <a >
                                                <img src="assets/images/reward/01.jpg" alt=""  />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="team-single-one-start">
                                        <div class="card-certified">
                                            <a >
                                                <img src="assets/images/reward/02.jpg" alt=""  />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="team-single-one-start">
                                        <div class="card-certified">
                                            <a >
                                                <img src="assets/images/reward/07.png" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="team-single-one-start">
                                        <div class="card-certified">
                                            <a >
                                                <img src="assets/images/reward/08.jpg" alt=""  />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="team-single-one-start">
                                        <div class="card-certified">
                                            <a >
                                                <img src="assets/images/reward/09.jpg" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="team-single-one-start">
                                        <div class="card-certified">
                                            <a >
                                                <img src="assets/images/reward/10.png" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Badge